/**
 * 字符串截取 (一个汉字算2个)
 * 
 * @param {string} txt 输入文本
 * @param {int} n 截取多少个字 一个汉字算2个
 * @param {boolean} needtip 是否需要全文提示
 * @param {string} postfix 自定义截断后缀，默认...
 * @returns 
 */
export function txtLeft(txt:string, n:number, needtip:boolean = false, postfix:string = '...') {
    if( txt == null || txt == "" ){
      return "";
    }

    var thislength = 0;

    for (var i = 0; i < txt.length; i++) {
      if (txt.charCodeAt(i) > 255) {
        thislength += 2;
      }
      else {
        thislength++;
      }
      if (thislength > n) {
        if(needtip){
          return '<span title="' + txt + '">' + txt.substring(0, i) + postfix + "</span>";
        }
        else{
          return txt.substring(0, i) + postfix;
        }
        break;
      }
    }
    return txt;
  }