

export default {
  /**
   * 插入样式
   * 
   * @param {any} css 
   */
  addStyle: function (css:string) {
    var head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');
  
    style.type = 'text/css';
    //@ts-ignore
    if (style.styleSheet) {
      //@ts-ignore
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  
    head.appendChild(style);
  },
  /**
   * 插入样式文件
   * 
   * @param {any} url 
   */
  addStyleUrl: function (url:string) {
    var head = document.head || document.getElementsByTagName('head')[0];
  
    var style = document.createElement('link');
  
    style.setAttribute("rel", "stylesheet");
    style.setAttribute("href", url);
  
    head.appendChild(style);
  }
}