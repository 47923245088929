/**
 * k线图
 */

var initParameter = require("../old/init/initParameter");
var initLayer = require("../old/init/initLayer");
var initIndexDOM = require("../old/init/initIndexDOM");             // 指标dom
var initDrawRegion = require("../old/init/drawRegion");

var dataFormat = require("../old/dataFormat");
var dataFormat2 = require("../old/dataFormat2");
var dataSplit = require("../old/dataSplit");

import {getdata, KlineTypes} from "./modules/data";




var loading = require("../old/common/loadingImg");
var loading2 = require("../old/common/loading");
var watermark = require("../old/common/watermark");       // 水印

var drawGrid = require("../old/drawGrid");
// var drawlien = require("../old/common/drawLine");
var drawBlockK = require("../old/drawBlockK");
var drawBlockTrading = require("../old/drawBlockTrading");
var drawIndexsV = require("../old/drawIndexsV");       // 指标5
var drawIndexsH = require("../old/drawIndexsH");       // 指标H
var drawTitle = require("../old/drawTitle");
var dopPoint2 = require("../old/dotPoint2");             // 打点
var drawCYQ = require("../old/drawCYQ");          // 筹码分布
var dotPointFormat = require("../old/dotPointFormat");     // 


var interactive = require("../old/interactive");
var interactiveForIpad = require("../old/interactiveForIpad");
var slidebar = require("../old/sildebar");
var sildebarForIpad = require("../old/sildebarForIpad");

var extend = require("../old/common/extend2");


var extensionAPI = require("../old/extensionAPI");     // 扩展api的实现

var getDeviceType = require("../old/common/getDeviceType");

var cyq = require("../old/common/cyq");
// var cqcx = require("../old/common/cqcx");       // 除权除息
// var setCode = require("../old/setCode");

var csstext = require("../css/box.css");
import style from "./modules/style";
import { txtLeft } from "./modules/text";
style.addStyle(csstext['default'])




class k{

  stauts:any
  option:any
  sdata:any
  options:any
  layer:any
  slidebar:any
  interactive:any
  loading:any
  data:any
  cyqCalc:any

  isfulldata = false
  /**
   * 有跳过的全量数据
   */
  full_jump_data:any
  /**
   * 全量数据
   */
  full_data:any = null
  /**
   * 部分数据
   */
  part_data:any  

  constructor(option: any){
    // k2
    var _this = this;
    this.option = option;
    

    this.stauts = {
      indexv: "CMA",
      indexh: "RSI"
    };

    try {
      this._init();
    } catch (error) {
      option.onError(error);
    }

    // this.options = extend({}, option) 

    // this.load();

    // this._getJSONP();
  }



  _init() {
    var dt = getDeviceType();

    this.sdata = {};
    this.sdata.dot = {};

    initParameter.call(this);       // 初始化参数
    
    initLayer.call(this);           // 初始化各个层

    initDrawRegion.call(this);          // 计算绘制区域

    initIndexDOM.popWin.call(this);            // 信息浮动窗
    initIndexDOM.cmfb.call(this);              // 筹码分布

    var show = this.options.show;

    if (!this.options.cyq) { // 当存在筹码分布就不显示主图指标
      initIndexDOM.main.call(this);               // 主图指标
    }
    if (show.index) {
      initIndexDOM.technology.call(this);         // 技术指标
    }
    if (show.minimap) {
      initIndexDOM.miniMap.call(this);            // 缩略图
    }

    var cc = this.layer.layerGridC;
    // drawGrid.verticalLine(this);            // 画竖线
    drawGrid.gridK(cc, this.options);                  // 绘制网格 - k线
    drawGrid.gridTrading(cc, this.options);            // 绘制网格 - 成交量
    if (show.index) {
      drawGrid.gridIndex(cc, this.options);              // 绘制网格 - 指标
    }

    var cyqw = 0;
    if (this.option.cyq) {
      var cyq = this.option.cyq;
      cyqw = cyq.width + (cyq.gap || 10);
    }
    // watermark.call(this, 0, cyqw);               // 水印
    watermark.call(this, 50, cyqw + this.options.width/2 - 170, 1);               // 水印

    // 判断设备类型
    if (dt == 1) {
      var sb = new sildebarForIpad(this);     // 缩略图
      this.slidebar = sb;
      this.interactive = new interactiveForIpad(this);
    } else {
      this.interactive = new interactive(this);      // 交互
      var sb = new slidebar(this);     // 缩略图
      this.slidebar = sb;
    }


    var ops = this.options;
    this.loading = new loading({
      width: ops.width,
      height: ops.height,
      bgColor: ops.color.background,
      ui: this.layer.layerUI
    });

  }


  load() {
    var ops = this.options;

    var load = new loading2({
      cc: this.layer.layerLoadingC,
      width: ops.width,
      height: ops.height
    });
    load.start();
  }

  _clear() {
    var root = document.querySelector(this.options.container);
    root.innerHTML = "";
    // root.removeChild(root.querySelector(".__ui"));
    // root.removeChild(root.querySelector(".__canvas"));
    root.className = root.className.replace("__emchatrs3_root_box", "");
  }

  // 清除所有canvas中绘制的内容
  _clearCanvas() {
    var layer = this.layer;
    var ops = this.options;
    for (var i = 0; i < layer.length; i++) {
      var temp = layer[i];
      if (temp.lineCap) {
        temp.clearRect(0, 0, ops.width, ops.height);
      }
    }
  }

  // 在首次draw的情况下
  /**
   * 在 拖动的时候绘制 | 首次绘制 | redraw绘制 
   */
  async draw() {
      

    if(this.sdata == undefined || this.sdata.k == undefined){
      let databack = await this.getAllData()
      if(databack === false) return false
    }
    if (this.stauts.indexh == 'OBV') {
      this.drawAll(false)
      return false
    }

  
    

    this.setData2({k:this.full_jump_data})
    this.draw2()

    this.setData({k:this.part_data})

    // return false
    // await this.draw2()
    // // return false
  
    // //if(this.sdata == undefined || this.sdata.k == undefined){
    //   await this.initData()
    // //}

    // // console.info(this.options)
    

    this.options.isdraw = true;
    var _this = this;

    var cyq = this.options.cyq || {};

    try {
      var stauts = this.stauts;

      // this.slidebar.drawFullData();

      // 绘制k线区域
      new drawBlockK(this).draw();

      // 绘制k线区域的均线
      new drawIndexsV(this).draw(stauts.indexv);
      drawTitle.titleK(this);     // 最后一笔的均线标题

      // 绘制成交量区域
      new drawBlockTrading(this).draw();
      drawTitle.titleTrading(this);     // 最后一笔的均线标题

      // 绘制指标H
      if (this.options.show.index) {
        var cc = this.layer.layerGridC;
        drawGrid.gridIndex(cc, this.options);              // 绘制网格 - 指标
        drawGrid.verticalLine(this, 3);
        new drawIndexsH(this).draw(stauts.indexh, 1);
        drawTitle.titleIndex(this);     // 绘制指标H标题
      }

      // console.time("mmmmmmm")
      // console.log(this);
      if (cyq.width) {
        drawCYQ.call(this);
      }
      // console.timeEnd("mmmmmmm")

      if (this.sdata.dot) {
        dopPoint2.call(this);
      }

      // 绘制完成的回调
      _this.options.onComplete();
    } catch (error) {
      _this.option.onError(error);
    }

    
  }


  async reDraw(){
    // await this.getAllData()

    let databack = await this.getAllData()
    if(databack === false) return false

    this.options.scale.start = undefined
    this.options.scale.end = undefined

    this.setData2({k:this.full_jump_data})
    this.draw2()

    this.setData({k:this.part_data})

    // return false
    // await this.draw2()
    // // return false
  
    // //if(this.sdata == undefined || this.sdata.k == undefined){
    //   await this.initData()
    // //}

    // // console.info(this.options)
    

    this.options.isdraw = true;
    var _this = this;

    var cyq = this.options.cyq || {};

    try {
      var stauts = this.stauts;

      // this.slidebar.drawFullData();

      // 绘制k线区域
      new drawBlockK(this).draw();

      // 绘制k线区域的均线
      new drawIndexsV(this).draw(stauts.indexv);
      drawTitle.titleK(this);     // 最后一笔的均线标题

      // 绘制成交量区域
      new drawBlockTrading(this).draw();
      drawTitle.titleTrading(this);     // 最后一笔的均线标题

      // 绘制指标H
      if (this.options.show.index) {
        var cc = this.layer.layerGridC;
        drawGrid.gridIndex(cc, this.options);              // 绘制网格 - 指标
        drawGrid.verticalLine(this, 3);
        new drawIndexsH(this).draw(stauts.indexh, 1);
        drawTitle.titleIndex(this);     // 绘制指标H标题
      }

      // console.time("mmmmmmm")
      // console.log(this);
      if (cyq.width) {
        drawCYQ.call(this);
      }
      // console.timeEnd("mmmmmmm")

      if (this.sdata.dot) {
        dopPoint2.call(this);
      }

      // 绘制完成的回调
      _this.options.onComplete();
    } catch (error) {
      _this.option.onError(error);
    }
  }

 async draw2() {

   
  
    if(this.sdata == undefined || this.sdata.k == undefined){
      await this.initData2()
    }

    // return false
    // console.info(this.options)
    

    this.options.isdraw = true;
    var _this = this;

    // var cyq = this.options.cyq || {};

    try {
      // var stauts = this.stauts;

      this.slidebar.drawFullData();

      // 绘制k线区域
      //new drawBlockK(this).draw();

      // 绘制k线区域的均线
      //new drawIndexsV(this).draw(stauts.indexv);
      //drawTitle.titleK(this);     // 最后一笔的均线标题

      // 绘制成交量区域
      //new drawBlockTrading(this).draw();
      //drawTitle.titleTrading(this);     // 最后一笔的均线标题

      // // 绘制指标H
      // if (this.options.show.index) {
      //   var cc = this.layer.layerGridC;
      //   drawGrid.gridIndex(cc, this.options);              // 绘制网格 - 指标
      //   drawGrid.verticalLine(this, 3);
      //   new drawIndexsH(this).draw(stauts.indexh, 1);
      //   drawTitle.titleIndex(this);     // 绘制指标H标题
      // }

      // console.time("mmmmmmm")
      // console.log(this);
      // if (cyq.width) {
      //   drawCYQ.call(this);
      // }
      // // console.timeEnd("mmmmmmm")

      // if (this.sdata.dot) {
      //   dopPoint2.call(this);
      // }

      // 绘制完成的回调
      //_this.options.onComplete();
    } catch (error) {
      _this.option.onError(error);
    }
  }


// 在首次draw的情况下
/**
 * 在 拖动的时候绘制 | 首次绘制 | redraw绘制 
 */
 async drawAll(needreload:boolean = true) {
 
  // if(this.sdata == undefined || this.sdata.k == undefined){
  //   await this.initData()
  // }

  // console.info(this.options)
  if (needreload || this.full_data == null) {
    await this.getOneAllData()
  }
  

  this.setDataAll({
    k: this.full_data,
    //klist: klist
  })
  

  this.options.isdraw = true;
  var _this = this;

  var cyq = this.options.cyq || {};

  try {
    var stauts = this.stauts;

    this.slidebar.drawFullData();

    // 绘制k线区域
    new drawBlockK(this).draw();

    // 绘制k线区域的均线
    new drawIndexsV(this).draw(stauts.indexv);
    drawTitle.titleK(this);     // 最后一笔的均线标题

    // 绘制成交量区域
    new drawBlockTrading(this).draw();
    drawTitle.titleTrading(this);     // 最后一笔的均线标题

    // 绘制指标H
    if (this.options.show.index) {
      var cc = this.layer.layerGridC;
      drawGrid.gridIndex(cc, this.options);              // 绘制网格 - 指标
      drawGrid.verticalLine(this, 3);
      new drawIndexsH(this).draw(stauts.indexh, 1);
      drawTitle.titleIndex(this);     // 绘制指标H标题
    }

    // console.time("mmmmmmm")
    // console.log(this);
    if (cyq.width) {
      drawCYQ.call(this);
    }
    // console.timeEnd("mmmmmmm")

    if (this.sdata.dot) {
      dopPoint2.call(this);
    }

    // 绘制完成的回调
    _this.options.onComplete();
    return true
  } catch (error) {
    _this.option.onError(error);
    return false
  }
}


  /**
   * dur： 表示拉升/缩短k线的方向，-1表示左侧，0表示两端拉升，1表示右侧
   * bl: 拉升的比例
   */
  // 拉长k线
  async elongate(dur: any, bl: any) {
    if (!this.isfulldata) {
      this.options.scale.start = undefined
      this.options.scale.end = undefined
      await this.drawAll()
      //return false
    }
    var ops = this.options;
    if (ops.onDragStart) {
      ops.onDragStart(ops.scale);
    }
    extensionAPI.elongate.call(this, dur, bl);
    if (ops.onDragEnd) {
      ops.onDragEnd(ops.scale);
    }
  }

  // 缩短k线
  async shorten(dur: any, bl: any) {
    if (!this.isfulldata) {
      this.options.scale.start = undefined
      this.options.scale.end = undefined
      await this.drawAll()
      //return false
    }
    var ops = this.options;
    if (ops.onDragStart) {
      ops.onDragStart(ops.scale);
    }
    extensionAPI.shorten.call(this, dur, bl)
    if (ops.onDragEnd) {
      ops.onDragEnd(ops.scale);
    }
  }


  // k.prototype.setOption = function (ops: any) {
  //   this._clear();

  //   this.option = extend(this.option, ops);

  //   this._init();


  //   dataFormat.formatK.call(this);          // 格式化数据
  //   dataSplit.slice.call(this);
  // }

  async initData(){

    
    let thisktype:keyof typeof KlineTypes = this.options.ktype

    // //获取底部全部数量
    // if (this.data == undefined || this.data.kbottom == undefined) {
    //   let drawSumWdith = this.options.drawRegion.drawSumWdith
    //   let bottomjson = await await getdata.getKlineData(this.options.code, {
    //     ktype: KlineTypes[thisktype],
    //     cfq: this.options.cfq,
    //     smplmt: drawSumWdith
    //   })

    // }
    

    
    
    let newjson = await getdata.getKlineData(this.options.code, {
      ktype: KlineTypes[thisktype],
      cfq: this.options.cfq,
      lastcount: this.options.scale.pillar + 24
      //smplmt: this.options.drawRegion.drawSumWdith,
    })
    
    // //如果不是全量数据
    // let klist
    // if (newjson.data.dktotal > newjson.data.klines.length) {
    //   this.options.isfulldata = false
    //   let newklinejson = await getdata.getKlineData(this.options.code, {
    //     ktype: KlineTypes[thisktype],
    //     cfq: this.options.cfq,
    //     lastcount: this.options.scale.pillar + 5
    //   })  
    //   klist = newklinejson.data.klines.map(function(v:any){
    //     v = v + '%'
    //     var temparray = v.split(',')
    //     return temparray.slice(0, 8).concat('-').join(',')
    //   })
    // }
    // else{
      
    //   this.options.isfulldata = true
    //   klist = newjson.data.klines.map(function(v:any){
    //     v = v + '%'
    //     var temparray = v.split(',')
    //     return temparray.slice(0, 8).concat('-').join(',')
    //   })    
    // }

    var newlist = newjson.data.klines.map(function(v:any){
      v = v + '%'
      var temparray = v.split(',')
      return temparray.slice(0, 8).concat('-').join(',')
    })

    var json = {
      name: newjson.data.name,
      data: newlist,
      info: {
        yc: newlist[newlist.length - 2].split(',')[2],
      }
    }


    
    this.setData({
      k: json,
      //klist: klist
    })


  }

   async initData2(){
    let thisktype:keyof typeof KlineTypes = this.options.ktype

    // //获取底部全部数量
    // if (this.data == undefined || this.data.kbottom == undefined) {
    //   let drawSumWdith = this.options.drawRegion.drawSumWdith
    //   let bottomjson = await await getdata.getKlineData(this.options.code, {
    //     ktype: KlineTypes[thisktype],
    //     cfq: this.options.cfq,
    //     smplmt: drawSumWdith
    //   })

    // }
    

    
    
    let newjson = await getdata.getKlineData(this.options.code, {
      ktype: KlineTypes[thisktype],
      cfq: this.options.cfq,
      //lastcount: this.options.scale.pillar + 24
      smplmt: this.options.drawRegion.drawSumWdith,
    })
    
    // //如果不是全量数据
    // let klist
    // if (newjson.data.dktotal > newjson.data.klines.length) {
    //   this.options.isfulldata = false
    //   let newklinejson = await getdata.getKlineData(this.options.code, {
    //     ktype: KlineTypes[thisktype],
    //     cfq: this.options.cfq,
    //     lastcount: this.options.scale.pillar + 5
    //   })  
    //   klist = newklinejson.data.klines.map(function(v:any){
    //     v = v + '%'
    //     var temparray = v.split(',')
    //     return temparray.slice(0, 8).concat('-').join(',')
    //   })
    // }
    // else{
      
    //   this.options.isfulldata = true
    //   klist = newjson.data.klines.map(function(v:any){
    //     v = v + '%'
    //     var temparray = v.split(',')
    //     return temparray.slice(0, 8).concat('-').join(',')
    //   })    
    // }

    var newlist = newjson.data.klines.map(function(v:any){
      v = v + '%'
      var temparray = v.split(',')
      return temparray.slice(0, 11).join(',')
    })

    var json = {
      name: newjson.data.name,
      data: newlist,
      info: {
        yc: newlist[newlist.length - 2].split(',')[2],
      }
    }
    
    this.setData2({
      k: json,
      //klist: klist
    })


  }

  

  /**
   * 重新设置K线图类型
   */
  async setKType(newktype:string){
    this.options.ktype = newktype
      this.options.scale.pillar = this.option.scale.pillar
      this.options.scale.start = undefined
      this.options.scale.end = undefined
      this.isfulldata = false
      this.full_data = null
    this.sdata = undefined
    this.draw()
  }

  /**
   * 重新设置除复权
   */
  async setCfq(newcfq:string){
    this.options.cfq = newcfq
    //await this.initData() 
      this.options.scale.pillar = this.option.scale.pillar
      this.options.scale.start = undefined
      this.options.scale.end = undefined
      this.isfulldata = false
      this.full_data = null
    this.sdata = undefined    
    this.draw()
  }

  //新接口转老接口
  transData(newjson:any){
    var newlist = newjson.data.klines.map(function(v:any){
      //v = v + '%'
      var temparray = v.split(',')
      return temparray.slice(0, 11).join(',')
    })

    
    var json = {
      name: txtLeft(newjson.data.name, 16, false, '..'),
      data: newlist,
      info: {
        //yc: newlist.length > 1?newlist[newlist.length - 2].split(',')[2]:newlist[newlist.length - 1].split(',')[2]
        yc: newjson.data.preKPrice
      }
    }
    return json
  }

  async getAllData(){
    let thisktype:keyof typeof KlineTypes = this.options.ktype

    let [
      newjson,
      newjson2
    ] = await Promise.all([
      await getdata.getKlineData(this.options.code, {
        ktype: KlineTypes[thisktype],
        cfq: this.options.cfq,
        //lastcount: this.options.scale.pillar + 24
        smplmt: this.options.drawRegion.drawSumWdith,
      }),
      await getdata.getKlineData(this.options.code, {
        ktype: KlineTypes[thisktype],
        cfq: this.options.cfq,
        lastcount: this.options.scale.pillar + 60
      })
    ])

    if( newjson2.data.klines.length == 0) return false

    this.full_jump_data = this.transData(newjson)
    this.part_data = this.transData(newjson2)

    // //计算起始第几根
    // let leftindex = newjson2.data.klines.length - this.options.scale.pillar
    // if (leftindex <= 0) {
    //   leftindex = 0
    // }

    // console.info(leftindex)
    // console.info(newjson2.data.klines[leftindex])
    
    
    this.options.scale.truepillar = this.getNearIndex(newjson, newjson2, this.option.scale.pillar)
    
  }

  async getOneAllData(){
    let thisktype:keyof typeof KlineTypes = this.options.ktype

    let alldata = await getdata.getKlineData(this.options.code, {
        ktype: KlineTypes[thisktype],
        cfq: this.options.cfq,
        lastcount: 1000000
      })
    this.isfulldata = true
    this.full_data = this.transData(alldata)
  }


  /**
   * 计算最靠近部分数据的真实起点index
   * @param data1 
   * @param data2 
   * @param pillar 
   */
  getNearIndex(data1:any, data2:any, pillar:number){
    var pillarindex = data2.data.klines.length - pillar
    if (pillarindex < 0) {
      pillarindex = 0
    }
    var pillardate = data2.data.klines[pillarindex].split(',')[0].replace(/-/ig,'')
    let pillartrueindex = 0
    
    for (let index = 0; index < data1.data.klines.length; index++) {
      let num1 = data1.data.klines[index].split(',')[0].replace(/-/ig,'')
      if (num1 > pillardate) {
        pillartrueindex = data1.data.klines.length - index
        break
      }
    }
    // console.info(pillartrueindex)
    
    return pillartrueindex
  }


  setData(data: any) { //, option: any

    // if (!this.sdata) {
    //   this.sdata = {};
    // }

    //if (data.k) {
      this.sdata.k = data.k;
    //}
    // if (data.klist) {
    //   this.sdata.klist = {};
    //   this.sdata.klist.data = data.klist
    // }  

    
    // if (data.dot) {
    //   // this.sdata.dot = data.dot;    // 打点数据
    //   for (var key in data.dot) {
    //     this.sdata.dot[key] = data.dot[key];
    //   }
    //   dotPointFormat.call(this);
    // }

    // if (this.options.show.cqcx) {
    //     // cqcx.call(this);
    // }

    
    //this.options = extend(this.options, option);
    // console.info(this.options.scale.data)
    dataFormat2.formatK.call(this);          // 格式化数据
    // dataFormat_kline.formatK.call(this)
    
    // console.info(this)
    

    dataSplit.slice.call(this);
    //  console.info(this.options.scale.data)

    var cyqpar = this.option.cyq;
    if (cyqpar) {
      var accuracyFactor = cyqpar.accuracyFactor || 150;
      var range = cyqpar.range || 120;
      var cyqCalc = new cyq(this.data.ks, accuracyFactor, range);
      this.cyqCalc = cyqCalc;
    }

  }


  setData2(data: any) { //, option: any

    if (!this.sdata) {
      this.sdata = {};
    }

    if (data.k) {
      this.sdata.k = data.k;
    }
    // if (data.klist) {
    //   this.sdata.klist = {};
    //   // this.sdata.klist.data = data.klist
    // }  

    
    // if (data.dot) {
    //   // this.sdata.dot = data.dot;    // 打点数据
    //   for (var key in data.dot) {
    //     this.sdata.dot[key] = data.dot[key];
    //   }
    //   dotPointFormat.call(this);
    // }

    // if (this.options.show.cqcx) {
    //     // cqcx.call(this);
    // }

    
    //this.options = extend(this.options, option);
    
    dataFormat.formatK.call(this);          // 格式化数据
    // dataFormat_kline.formatK.call(this)
    
// return false

    dataSplit.slice.call(this);


    // var cyqpar = this.option.cyq;
    // if (cyqpar) {
    //   var accuracyFactor = cyqpar.accuracyFactor || 150;
    //   var range = cyqpar.range || 120;
    //   var cyqCalc = new cyq(this.data.ks, accuracyFactor, range);
    //   this.cyqCalc = cyqCalc;
    // }

  }


  setDataAll(data: any) {

    if (!this.sdata) {
      this.sdata = {};
    }

    if (data.k) {
      this.sdata.k = data.k;
    }
    if (data.klist) {
      this.sdata.klist = {};
      this.sdata.klist.data = data.klist
    }  

    
    if (data.dot) {
      // this.sdata.dot = data.dot;    // 打点数据
      for (var key in data.dot) {
        this.sdata.dot[key] = data.dot[key];
      }
      dotPointFormat.call(this);
    }

    // if (this.options.show.cqcx) {
    //     // cqcx.call(this);
    // }

    
    // this.options = extend(this.options, option);

    dataFormat.formatK.call(this);          // 格式化数据
    
    dataSplit.slice.call(this);
    //  console.info(this.options.scale.data)

    var cyqpar = this.option.cyq;
    if (cyqpar) {
      var accuracyFactor = cyqpar.accuracyFactor || 150;
      var range = cyqpar.range || 120;
      var cyqCalc = new cyq(this.data.ks, accuracyFactor, range);
      this.cyqCalc = cyqCalc;
    }

  }

  getData() {
    var stauts = this.stauts;
    var datas = this.options.scale;
    var idxs = {
      [stauts.indexv]: datas.indexs[stauts.indexv],
      [stauts.indexh]: datas.indexs[stauts.indexh],
      VAVERAGE: datas.indexs.VAVERAGE
    };

    var res = {
      data: datas.data,
      indexs: idxs,
    }
    return res;
  }

  // 




  getThisIndex() {
    return this.options.thisData;
  }


  getBoundingRect() {
    var pad = this.options.padding;
    var kr = this.options.drawRegion.k
    return {
      left: pad.left,
      right: pad.right,
      top: pad.top + kr.mt + kr.top,
      bottom: pad.bottom
    }
  }

  start(isCover: any) {
    this.loading.start(isCover);
  }

  stop() {
    this.loading.stop();
  }

}

export default k

