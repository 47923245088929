import _ from "lodash"

/**
 * 获取数据
 */


/**
 * 除复权
 */
enum Cfq{
  /**
   * 不复权
   */
  Bfq = 0,
  /**
   * 前复权
   */
  Qfq = 1,
  /**
   * 后复权
   */
  Hfq = 2
}

/**
 * K线图类型
 */
enum KlineType{
  /**
   * 日K
   */
  D = 101,
  /**
   * 周K
   */  
  W = 102,
  /**
   * 月K
   */
  M = 103,
  /**
   * 5分钟K
   */
  M5 = 5,
  /**
   * 15分钟K
   */
  M15 = 15,
  /**
   * 30分钟K
   */
  M30 = 30,
  /**
   * 60分钟K
   */
  M60 = 60
}

/**
 * K线图options
 */
interface Klineoptions{
  /**
   * K线图类型
   */
  ktype?: KlineType,
  /**
   * 除复权
   */
  cfq?: Cfq,
  /**
   * 开始日期
   */
  begin?: number
  /**
   * 结束日期
   */
  end?: number,

  /**
   * 均匀返回最多多少条
   */
  smplmt?:number,
  /**
   * 最近多少条数据
   */
  lastcount?: number
}

export let getdata = {
  /**
   * 获取K线图数据
   * @param code 代码
   * 
   */
  async getKlineData(code:string, options:Klineoptions = {}){

    //默认参数
    let thisoptions = _.merge({
      ktype: KlineType.D,
      cfq: Cfq.Bfq,
      begin: 0,
      end: 20500101,
      smplmt: 1000000,
      lmt: 1000000
    }, options)

 
    let rnd = Math.floor(Math.random() * 99 + 1) //泛域名
    let ishttps = location.protocol == 'https:' //是否是https

    let url = 'http://' + rnd + '.push2his.eastmoney.com/api/qt/stock/kline/get?cb=?'
    if(ishttps){
      url = 'https://push2his.eastmoney.com/api/qt/stock/kline/get?cb=?'
    } 

    if(window.location.search.indexOf('env=test')>-1){
      url = '//push2test.eastmoney.com/api/qt/stock/kline/get?cb=?'
    }

    let getparams = {
      secid: code, //smplmt
      ut: 'fa5fd1943c7b386f172d6893dbfba10b',
      fields1: 'f1,f2,f3,f4,f5,f6',
      fields2: 'f51,f52,f53,f54,f55,f56,f57,f58,f59,f60,f61',
      klt: thisoptions.ktype,
      fqt: thisoptions.cfq,
      beg: thisoptions.begin,
      end: thisoptions.end,
      smplmt: thisoptions.smplmt,
      lmt: thisoptions.lmt
    }

    if (options.lastcount) {
      getparams.lmt = options.lastcount
      delete getparams.beg
      delete getparams.smplmt
    }    

    return $.ajax({
      method: 'GET',
      url: url,
      dataType: 'jsonp',
      data: getparams
    })
    // return $.ajax({
    //   method: 'GET',
    //   url: '/test/he.json',
    //   dataType: 'json',
    //   data: getparams
    // })
  }
}

export let KlineTypes = KlineType